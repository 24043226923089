.inline {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0px;
}

p.inline {
    font-family: "Noto Sans Mono","Segoe UI Mono","Courier New",Courier,monospace;;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    margin: 0px 8px;
    text-decoration: none;
    color: black;
}

kendo-dropdownbutton#languageUserOptions>.k-button {
    padding: 0 14px 0 0;
}

span.k-link.k-menu-link {
    padding: 0
}